// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Page from "blocks/views/Layouts/Page";
import Image from "next/image";
import { useRouter } from "next/router";

const FourOhFour = () => {
  const router = useRouter();
  return (
    <Page
      data-testid="component-404"
      title="404: Not found"
      role="alert"
      sx={{ py: 3 }}
    >
      <Container maxWidth="md">
        <Typography align="center" variant={"h1"} color="textPrimary">
          404
        </Typography>
        <Typography align="center" variant={"h4"} color="textSecondary">
          Sorry. Page not found.
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <Image
            src="/static/images/brokenlink.svg"
            alt="404: Not Found"
            width={200}
            height={200}
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography
            align="center"
            variant={"subtitle2"}
            color="textSecondary"
          >
            Clearly something went wrong. The analytics engine would have
            recorded it and our engineers will try to fix, if there is an issue.
            Meanwhile, would be a good idea to Go back to the homepage and start
            afresh.
          </Typography>
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            data-testid="404-buttonTest"
            color="secondary"
            variant="contained"
            onClick={() => router.push("/")}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default FourOhFour;
